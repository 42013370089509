import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

export default ({ tips }) => (

  <ArticleColumn>
    <ArticleInner to={`/tip/${tips.slug}`}>

    <ArticleBackground theme={tips.theme} background={tips.backgroundColour}>

        <ArticleTypeWrapper>
          <ArticleType className={tips.type.toString().toLowerCase().replace(/\s/g, "-")}>
            {tips.type}
          </ArticleType>
        </ArticleTypeWrapper>
          <BlogTitle theme={tips.theme}>
            {tips.title}
          </BlogTitle>
          <PreviewParagraph theme={tips.theme}
            dangerouslySetInnerHTML={{
              __html: tips.teaserText,
            }}
          />

    </ArticleBackground>

    </ArticleInner>
  </ArticleColumn>
)

const ArticleColumn = styled.div `
  width: calc(33.333% - 16px);
  margin-right: 24px;
  display: flex;
  margin-bottom 24px;
  transition: all 240ms ease-in-out;
  align-items: flex-start;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 24px;
    }
  }

  @media (max-width: 540px) {
    width: 100%;
    margin-right: 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  > a {
    background-color: #2B2B2B;
    height: 100%;
    border-radius: 8px;
    min-height: 267px;
  }
`

const ArticleInner = styled(props => <Link {...props} />)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  text-decoration: none;
  margin-bottom: 0;
`

const ArticleBackground = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 48px;
`

const InnerArticleBackground = styled.div `
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 48px;
`

const ArticleType = styled.span `
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  margin-bottom: 24px;
  width: 100%;

  &.user-interface {
    color: #67DFFF;
  }

  &.design-systems {
    color: #FF7979;
  }

  &.user-experience {
    color: #FFD167;
  }

  &.content {
    color: #79FF9B;
  }

  &.components {
    color: #7467FF;
  }
`

const BlogTitle = styled.h3`
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 12px;
  margin-top: 0;
  display: block;
  width: 100%;
`

const PreviewParagraph = styled.p `
  color: #9C9C9C;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 400;
  width: 100%;
`

const ArticleTypeWrapper = styled.div `
  display: flex;
  align-items: center;
`

const PostImageContainer = styled.div `
  margin-top: auto;
  max-width: 48px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 240ms ease-in-out;

  .gatsby-image-wrapper, img, picture, picture img {
    height: 48px;
    width: 100%;
    object-fit: contain;
  }
`