import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'

//Components
import Hero from '../components/heros/homepage-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import TipPreview from '../components/teasers/tip-preview'
import VideoPreview from '../components/teasers/video-preview'
import EmailCtaSection from '../components/email-signup/email-cta-section'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const tips = get(this, 'props.data.allContentfulTip.edges')
    const videos = get(this, 'props.data.allContentfulVideo.edges')

    return (
      <div>
        <TopImage src="/content/watercolour-1.png" />
        <Navigation />
        <Layout location={this.props.location} >
          <Helmet title={siteTitle} />
          <Hero />
          <div className="wrapper container boxed-width section-padding-bottom">
            <ComponentHeader>
              <h2>Latest tips</h2>
              <Link to="/tips" className="button outlines"> View all the tips </Link>
            </ComponentHeader>

            <div className="container">
              <div className="container">
                <BlogList className="article-list container justify-content-start">
                    {tips.map(({ node }) => {
                        return (
                          <TipPreview tips={node} key={node.slug}/>
                        )
                    })}
                </BlogList>
              </div>
            </div>
          </div>

          <div className="wrapper container boxed-width section-padding-bottom">
            <ComponentHeader>
              <h2>Latest videos</h2>
              <Link to="/tips" className="button outlines"> View all the videos </Link>
            </ComponentHeader>

            <div className="container">
              <div className="container">
                <BlogList className="article-list container justify-content-start">
                    {videos.map(({ node }) => {
                        return (
                          <VideoPreview video={node} key={node.slug} />
                        )
                    })}
                </BlogList>
              </div>
            </div>
          </div>

          <EmailCtaSection />

        </Layout>
      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulVideo(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          thumbnail {
            fluid(quality: 100) {
              src
              tracedSVG
              aspectRatio
              srcSet
              sizes
            }
          }
          description
        }
      }
    }
    allContentfulTip(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          type
          teaserText
        }
      }
    }
  }
`

// Styles

const BlogList = styled.div`
  list-style: none;
  margin-bottom: -24px;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const TopPageHighlight = styled.img `
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  z-index: 0;
`

const ComponentHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  h2 {
    margin-bottom: 0;
  }
`

const TopImage = styled.img `
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  z-index: -1;
  max-width: 720px;
`