import React from 'react'
import MediumImage from 'gatsby-image'
import styled, { css } from 'styled-components'
import sitelogo from '../../images/ui-ux-tips-logo.svg';

export default ({ data }) => (
  <div>

    <ImageContainer className="container boxed-width">

      <TextColumn className="col-6 col-12-t">
        <HeroTextContainer>
          <HeroHeading>User interface and user experience design tips.</HeroHeading>
          <HeroParagraph>I find all of the design tips so you don’t have to. With tips, articles, and resources available for everything UI & UX.</HeroParagraph>
        </HeroTextContainer>
      </TextColumn>

      <PanelContainer className="col-5 col-12-t">
      </PanelContainer>

    </ImageContainer>

  </div>
)

// Styled components
const ImageContainer = styled.div`
  padding-top: 120px;
  padding-bottom: 144px;
`

const NormalImage = styled.img`
  height: auto;
  max-width: 282px;
  object-fit: contain;
  object-position: center;
  position: relative;
  z-index: 2;
  border-radius: 10px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`
const TextColumn = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const HeroTextContainer = styled.div`
  text-align: left;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

const HeroHeading = styled.h1`
  line-height: 1.25;
  font-size: 48px;
`

const HeroParagraph = styled.p `
  margin-bottom: 0;
  max-width: 486px;
`